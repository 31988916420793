import $ from 'jquery';
$(function ($) {
	const $currentEventHeaderItem = $('.headerEvents__item.-isSection');

	$('.subNav').addClass('-style-match-' + $currentEventHeaderItem.index());

	$('.eventsSubNav').slideUp(0);

	$(window).on('click', function () {
		$('.modal').removeClass('show-modal');
		$('.subNav:not(.eventsSubNav)').css('z-index', '998');
		$('.pageHeader').css('z-index', '999');
		$('.topHeader').css('z-index', '1000');
	});

	$('.modal__trigger').on('click', function () {
		$(this).siblings().toggleClass('show-modal');
		$('.subNav').css('z-index', '99');
		$('.pageHeader').css('z-index', '99');
		$('.topHeader').css('z-index', '100');
	});

	$('.modal__trigger, .modal__content').on('click', function (event) {
		event.stopPropagation();
	});

	$('.headerEvents__link').on('click', function (event) {
		if (window.matchMedia('(max-width: 767px)').matches) {
			event.preventDefault();

			$('.headerEvents__link').not(this).siblings('.subNav').slideUp();

			$('.headerEvents__link')
				.not(this)
				.find('.fa-angle-down')
				.removeClass('-rotated');
			$(this).siblings('.subNav').slideToggle();
			$(this).find('.fa-angle-down').toggleClass('-rotated');
		}
	});

	function revealLabel(ID) {
		let label = $('#' + ID);
		label.removeClass('-hidden');
	}

	function resetLabels() {
		let labels = $('.socialShares__shareToText');
		labels.addClass('-hidden');
	}

	$('.js-displayText')
		.on('mouseover', function () {
			revealLabel($(this).data('linked-media'));
		})
		.on('mouseout', function () {
			resetLabels();
		});
});
